<template>
  <FocusLoop>
    <div class="modal_page center">
      <form
        @submit.prevent="post"
        ref="account_modal"
        class="account_modal d-flex d-col space-between"
      >
        <div class="d-flex space-between align-center">
          <div class="font-18">{{ $t("modal.timezone.title") }}</div>

          <button type="button" class="center" @click="closeModal">
            <s-icon height="16" color="white">close</s-icon>
          </button>
        </div>
        <div class="center mt-10 missingText red--text font-14">
          {{ message }}
        </div>
        <div>
          <s-timezone
            v-model="timezone"
            :prefill="timezones"
            height="40"
          ></s-timezone>
        </div>

        <div class="d-flex justify-end mt-40">
          <s-btn
            @click.native="closeModal"
            type="button"
            height="36"
            class="mr-10"
            >{{ $t("modal.cancel") }}</s-btn
          >
          <s-btn type="submit" height="36" class="green">{{
            $t("modal.save")
          }}</s-btn>
        </div>
      </form>
    </div>
  </FocusLoop>
</template>

<script>
import { mapState } from "vuex";
import Timezone from "../Atoms/Timezone.vue";
import { gsap } from "gsap";
export default {
  components: { Timezone },
  data() {
    return {
      name: "",
      selected: 1,
      message: "",
      timezone: "",
    };
  },
  methods: {
    post() {
      this.$store.commit("schedule/setTimezone", this.timezone);
      this.closeModal();
    },
    select(val) {
      this.selected = val;
    },
    closeModal() {
      gsap.to(".modal_page", {
        opacity: 0,
        duration: 0.3,
        ease: "power2.out",
      });
      gsap.to(".account_modal", {
        scale: 0.7,
        duration: 0.3,
        ease: "power2.out",
        onComplete: () => this.$store.commit("modals/timezoneModal", false),
      });
    },
  },
  computed: {
    ...mapState("attendees", ["edit"]),
    ...mapState("schedule", ["timezones"]),
    action() {
      return this.edit ? "Edit" : "Create";
    },
  },
  mounted() {
    if (this.edit) {
      this.name = this.edit.title;
      this.selected = this.edit.color;
    }

    gsap.from(".modal_page", {
      opacity: 0,
      duration: 0.3,
      ease: "power2.out",
    });

    gsap.from(".account_modal", {
      scale: 0.7,
      duration: 0.3,
      ease: "power2.out",
    });
  },
};
</script>

<style scoped>
.modal_page {
  position: fixed;
  left: 0;
  top: 0px;
  width: 100vw;
  height: 100vh;
  background: rgba(41, 41, 41, 0.6);
  z-index: 8;
}

.account_modal {
  width: 450px;
  padding: 30px;
  padding-left: 60px;
  padding-right: 60px;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.35);
  background-color: #243748;
}

.grid {
  display: grid;
  grid-template-columns: 150px auto;
  grid-row-gap: 15px;
}

.color {
  height: 32px;
  width: 32px;
  border-radius: 50%;
  margin: 5px;
  border: 1px solid rgba(0, 0, 0, 0);
}

.color:hover {
  box-shadow: 0 0 12px var(--green);
}

.active {
  box-shadow: 0 0 20px var(--green);
  border: 1px solid rgb(255, 255, 255);
}

@media only screen and (max-width: 576px) {
  .account_modal {
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
  }
}
</style>