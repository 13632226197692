<template>
  <div class="blurOverlay">
    <One />
  </div>
</template>

<script>
import One from "./One";
export default {
  components: {
    One,
  },
  mounted() {
     
  }
};
</script>

<style scoped>



</style>