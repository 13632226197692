<template>
  <div class="blurOverlay">s</div>
</template>

<script>
export default {
  mounted(){

  }

};
</script>

<style >
.blurOverlay{
  position: absolute;
  height: 100vh;
  width: 100vw;
  z-index: 3;
  top: 0;
  filter: blur(2px);
}



</style>