<template>
  <FocusLoop>
    <div class="modal_page center" ref="modal_page">
      <form
        @keyup.enter="post"
        @submit.prevent="post"
        ref="modal_window"
        class="account_modal d-flex d-col space-between"
      >
        <div class="d-flex space-between align-center">
          <div class="font-18">{{ action }} {{ $t("modal.addRoom.room") }}</div>
          <s-icon @click.native="closeModal" color="grey">close</s-icon>
        </div>
        <div class="center mt-10 missingText red--text font-14">
          {{ message }}
        </div>
        <div class="grid align-center mt-20">
          <div class="font-14 opacity-60">{{ $t("modal.addRoom.name") }}</div>
          <s-text-field autofocus ref="name" v-model="name"></s-text-field>
        </div>
        <div class="opacity-60 mt-20 mb-20 text-center">
          {{ $t("modal.addRoom.assign") }}
        </div>
        <div class="w100 d-flex wrap center">
          <button
            type="button"
            v-for="item in 20"
            :key="item"
            class="color"
            @click="select(item)"
            :class="{ active: item == selected }"
            :style="{ backgroundColor: 'var(--sm-color-' + item + ')' }"
          ></button>
        </div>
        <div class="d-flex justify-end mt-40">
          <div
            @click="check = !check"
            v-if="!edit"
            class="center mr-auto pointer noselect"
          >
            <s-checkbox v-model="check"></s-checkbox>
            <span class="font-14 ml-10">
              {{ $t("modal.keep_open") }}
            </span>
          </div>
          <s-btn
            @click.native="closeModal"
            type="button"
            height="36"
            class="mr-10"
          >
            {{ $t("modal.cancel") }}</s-btn
          >
          <s-btn
            v-if="edit"
            type="button"
            @click.native="del"
            height="36"
            class="red mr-10"
          >
            {{ $t("modal.delete") }}</s-btn
          >
          <s-btn :disabled="block" type="submit" height="36" class="green">
            {{ $t("modal.save") }}
          </s-btn>
        </div>
      </form>
    </div>
  </FocusLoop>
</template>

<script>
import { mapState } from "vuex";
import { gsap } from "gsap";
export default {
  data() {
    return {
      name: "",
      selected: 1,
      message: "",
      block: false,
      check: false,
    };
  },
  methods: {
    async post() {
      this.message = "";
      this.$refs.name.onFocus();
      if (this.validate()) return;
      let data = {
        title: this.name,
        color: this.selected,
      };
      let response;
      if (this.edit) {
        let ob = this.rooms.find((el) => this.search(el));
        if (ob) return this.notification();
        data.id = this.edit.id;
        response = await this.$store.dispatch("rooms/updateRoom", data);
      } else {
        this.block = true;
        response = await this.$store.dispatch("rooms/postRoom", data);
        if (response.status == "error") {
          this.block = false;
          return this.$store.commit("notifications/push", {
            error: this.$t("server." + response.errorCode),
          });
        }
      }
      if (response.error) this.message = response.errorCode;
      if (!response.error && !this.check) this.closeModal();
      this.$store.dispatch("rooms/getRoomsInformation");
      this.$store.dispatch("calendar/getCalendarEvents");
      this.block = false;

      if (!response.error) {
        this.name = "";
        this.selected = 1;
      }
    },
    async del() {
      this.$store.commit("rooms/editRoom", this.edit);
      this.$store.commit("modals/deleteRoomConfirmationModal", true);
      this.closeModal();
    },
    select(val) {
      this.selected = val;
    },
    validate() {
      if (this.name.length > 0) return;
      this.message = this.$t("modal.addRoom.room_name");
      return true;
    },
    search(el) {
      return el.title == this.name && el.id !== this.edit.id;
    },
    notification() {
      return this.$store.commit("notifications/push", {
        error: this.$t("server.room_already_exists"),
      });
    },
    closeModal() {
      gsap.to(this.$refs.modal_page, {
        opacity: 0,
        duration: 0.3,
        ease: "power2.out",
      });
      gsap.to(this.$refs.modal_window, {
        scale: 0.7,
        duration: 0.3,
        ease: "power2.out",
        onComplete: () => {
          this.$store.commit("modals/roomModal", false);
          this.block = false;
        },
      });
    },
  },
  computed: {
    ...mapState("rooms", ["edit", "rooms"]),
    action() {
      return this.edit
        ? this.$t("modal.addRoom.edit")
        : this.$t("modal.addRoom.create");
    },
  },
  mounted() {
    if (this.edit) {
      this.name = this.edit.title;
      this.selected = this.edit.color;
    }
    gsap.from(this.$refs.modal_page, {
      opacity: 0,
      duration: 0.3,
      ease: "power2.out",
    });

    gsap.from(this.$refs.modal_window, {
      scale: 0.7,
      duration: 0.3,
      ease: "power2.out",
    });
  },
};
</script>

<style scoped>
.modal_page {
  position: fixed;
  left: 0;
  top: 0px;
  width: 100vw;
  height: 100vh;
  background: rgba(41, 41, 41, 0.6);
  z-index: 8;
}

.account_modal {
  width: 450px;
  padding: 30px;
  padding-left: 60px;
  padding-right: 60px;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.35);
  background-color: #243748;
}

.grid {
  display: grid;
  grid-template-columns: 150px auto;
  grid-row-gap: 15px;
}

.color {
  height: 32px;
  width: 32px;
  border-radius: 50%;
  margin: 5px;
  border: 1px solid rgba(0, 0, 0, 0);
}

.color:hover {
  box-shadow: 0 0 12px var(--green);
}

.active {
  box-shadow: 0 0 20px var(--green);
  border: 1px solid rgb(255, 255, 255);
}

@media only screen and (max-width: 576px) {
  .account_modal {
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
  }
}
</style>