import { render, staticRenderFns } from "./Drawer.vue?vue&type=template&id=32ae95f1&scoped=true&"
import script from "./Drawer.vue?vue&type=script&lang=js&"
export * from "./Drawer.vue?vue&type=script&lang=js&"
import style0 from "./Drawer.vue?vue&type=style&index=0&id=32ae95f1&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32ae95f1",
  null
  
)

export default component.exports