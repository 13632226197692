<template>
  <FocusLoop>
    <div class="modal_page center">
      <form
        @submit.prevent="post"
        class="modal_window d-flex d-col space-between"
      >
        <div class="d-flex space-between align-center">
              <div  v-if="edit.attendees" class="font-18">{{ $t("modal.deleteAttendee.group") }}</div>
          <div v-if="!edit.attendees" class="font-18">{{ $t("modal.deleteAttendee.title") }}</div>
          <s-icon @click.native="closeModal" color="grey">close</s-icon>
        </div>

        <div class="pt-30 opacity-60 font-14">
          <span> {{ $t("modal.deleteAttendee.note") }}</span>
          <span>"</span>
          <span v-if="!edit.attendees" class="weight-600">{{
            edit.email
          }}</span>
          <span v-if="edit.attendees" class="weight-600">{{ edit.name }}</span>
          <span>" ?</span>
        </div>

        <div class="d-flex justify-end mt-40">
          <s-btn @click.native="cancel" type="button" height="36" class="mr-10">
            {{ $t("modal.cancel") }}
          </s-btn>
          <s-btn type="submit" height="36" class="red">
            {{ $t("modal.delete") }}</s-btn
          >
        </div>
      </form>
    </div>
  </FocusLoop>
</template>

<script>
import { mapState } from "vuex";
import { gsap } from "gsap";
export default {
  data() {
    return {};
  },
  methods: {
    async post() {
      if (!this.edit.attendees) {
        await this.$store.dispatch("attendees/deleteAttendee", this.edit.id);
        await this.$store.dispatch("attendees/getAttendeesInformation");
      } else {
        await this.$store.dispatch("attendees/deleteGroup", this.edit.id);
        await this.$store.dispatch("attendees/getGroups");
      }
      gsap.to(".modal_page", {
        opacity: 0,
        duration: 0.3,
        ease: "power2.out",
      });
      gsap.to(".modal_window", {
        scale: 0.7,
        duration: 0.3,
        ease: "power2.out",
        onComplete: () => this.onComplete(),
      });
    },
    onComplete() {
      this.$store.commit("modals/deleteAttendeeConfirmationModal", false);
    },
    cancel() {
      this.closeModal();
    },
    closeModal() {
      gsap.to(".modal_page", {
        opacity: 0,
        duration: 0.3,
        ease: "power2.out",
      });
      gsap.to(".modal_window", {
        scale: 0.7,
        duration: 0.3,
        ease: "power2.out",
        onComplete: () => this.onComplete(),
      });
    },
  },
  computed: {
    ...mapState("attendees", ["edit"]),
  },
  mounted() {
    gsap.from(".modal_page", {
      opacity: 0,
      duration: 0.3,
      ease: "power2.out",
    });

    gsap.from(".modal_window", {
      scale: 0.7,
      duration: 0.3,
      ease: "power2.out",
    });
  },
};
</script>

<style scoped>
.modal_page {
  position: fixed;
  left: 0;
  top: 0px;
  width: 100vw;
  height: 100vh;
  background: rgba(41, 41, 41, 0.6);
  z-index: 8;
}

.modal_window {
  width: 450px;
  padding: 30px;
  padding-left: 60px;
  padding-right: 60px;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.35);
  background-color: #243748;
}

@media only screen and (max-width: 576px) {
  .modal_window {
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
  }
}
</style>