<template>
  <FocusLoop>
    <div class="modal_page center">
      <form
        @submit.prevent="post"
        class="modal_window d-flex d-col space-between"
      >
        <div class="d-flex space-between align-center">
          <div class="font-18">{{ $t("modal.saveEvent.title") }}</div>
          <s-icon @click.native="closeModal" color="grey">close</s-icon>
        </div>
        <div class="">
          <s-radio
            class="py-30"
            vertical
            v-model="radio"
            :options="options"
          ></s-radio>

          <label for="" class="font-14 opacity-60">{{
            $t("modal.saveEvent.email")
          }}</label>
          <s-text-area
            :minHeight="110"
            :placeholder="$t('modal.saveEvent.placeholder')"
            class="pt-5"
          ></s-text-area>
          <span class="font-12 opacity-60">{{
            $t("modal.saveEvent.meeting")
          }}</span>
        </div>

        <div class="d-flex justify-end mt-40">
          <s-btn
            @click.native="closeModal"
            type="button"
            height="36"
            class="mr-10"
          >
            {{ $t("modal.close") }}
          </s-btn>
          <s-btn type="submit" height="36" class="green">
            {{ $t("modal.save") }}</s-btn
          >
        </div>
      </form>
    </div>
  </FocusLoop>
</template>

<script>
import { mapState } from "vuex";
import { gsap } from "gsap";
export default {
  data() {
    return {
      options: [
        this.$t("modal.saveEvent.this_meeting"),
        this.$t("modal.saveEvent.following_meetings"),
      ],
      radio: 0,
    };
  },
  methods: {
    async post() {
      if (this.radio === 0) await this.$store.dispatch("schedule/updateEvent");
      if (this.radio === 1)
        await this.$store.dispatch("schedule/updateAllEvents");
      this.$store.commit("schedule/resetState");
      this.closeModal();
    },
    closeModal() {
      gsap.to(".modal_page", {
        opacity: 0,
        duration: 0.3,
        ease: "power2.out",
      });
      gsap.to(".modal_window", {
        scale: 0.7,
        duration: 0.3,
        ease: "power2.out",
        onComplete: () =>
          this.$store.commit("modals/saveEventConfirmation", false),
      });
    },
  },
  computed: {},
  mounted() {
    gsap.from(".modal_page", {
      opacity: 0,
      duration: 0.3,
      ease: "power2.out",
    });

    gsap.from(".modal_window", {
      scale: 0.7,
      duration: 0.3,
      ease: "power2.out",
    });
  },
};
</script>

<style scoped>
.modal_page {
  position: fixed;
  left: 0;
  top: 0px;
  width: 100vw;
  height: 100vh;
  background: rgba(41, 41, 41, 0.6);
  z-index: 8;
}

.modal_window {
  width: 450px;
  padding: 30px;
  padding-left: 60px;
  padding-right: 60px;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.35);
  background-color: #243748;
}

@media only screen and (max-width: 576px) {
  .modal_window {
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
  }
}
</style>