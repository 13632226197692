import { del, get, post } from "../assets/functions";
import store from "./index";

export default {
  namespaced: true,
  state: {
    attendees: [],
    groups: [],
    edit: null,
  },
  mutations: {
    editAttendee(state, response) {
      state.edit = response;
    },
    clearAttendee(state) {
      state.edit = null;
    },
    setData(state, response) {
      state.attendees = response;
    },
    setGroup(state, response) {
      state.groups = response;
    },
    editColor(state, response) {
      state.edit.color = response;
    },
  },
  actions: {
    //Attendees
    async getAttendeesInformation(context) {
      let response = await get("/api/attendees");
      context.commit("setData", response);
      return response;
    },

    async postAttendee(context, data) {
      let response = await post("/api/attendee", data);
      store.commit("notifications/raw", response);
      await context.dispatch("getAttendeesInformation");
      return response;
    },

    async updateAttendees(context, data) {
      if (!data) data = context.state.edit;
      let response = await post("/api/attendee/update", data);
      store.commit("notifications/raw", response);
      await context.dispatch("getAttendeesInformation");
      return response;
    },
    async deleteAttendee(context, val) {
      let response = await del("/api/attendee/" + val);
      store.commit("notifications/raw", response);
      context.dispatch("getAttendeesInformation");
      return response;
    },

    //Groups
    async getGroups(context) { 
      let response = await get("/api/groups/attendees");
      context.commit("setGroup", response);
      return response;
    },
    async postGroup(context, data) {

      let response = await post("/api/groups/attendees", data);
      store.commit("notifications/raw", response);
      await context.dispatch("getGroups");
      return response;
    },
    async updateGroups(context, data) {
      if (!data) data = context.state.edit;
      let response = await post("/api/groups/attendees/update", data);
      store.commit("notifications/raw", response);
      await context.dispatch("getAttendeesInformation");
      return response;
    },
    async deleteGroup(context, val) {
      let response = await del("/api/groups/attendees/" + val);
      store.commit("notifications/raw", response);
      context.dispatch("getAttendeesInformation");
      return response;
    },
  },
};
