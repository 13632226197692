<template>
  <transition name="dropdown-top">
    <div v-if="show" class="notification noselect" @click.prevent="closeModal">
      <div class="d-flex w100">
        <div v-if="data.error" class="grow center">{{ data.error }}</div>
        <div v-if="data.success" class="grow center">
          {{ success(data.success) }}
        </div>
        <s-icon v-if="data.error" width="24" color="red" class="pl-20"
          >close</s-icon
        >
        <s-icon v-if="data.success" width="24" color="green" class="pl-20"
          >check</s-icon
        >
      </div>
    </div>
  </transition>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      show: false,
      data: {},
      buffer: [],
      processing: false,
      close: false,
      interval: null,
      count: 1,
    };
  },
  methods: {
    async process() {
      if (this.buffer == 0) return;
      if (this.processing) return;
      this.processing = true;
      this.show = true;
      for (let x of this.buffer) {
        this.renderNotification(x);
        await this.timer();
        clearInterval(this.invterval);
        this.close = false;
        if (!this.show) break;
      }
      this.$store.commit("notifications/clearData");
      this.data = {};
      this.buffer = [];
      this.processing = false;
      this.show = false;
    },
    async renderNotification(x) {
      this.data = {};
      await this.$wait();
      this.data = x;
    },
    async timer() {
      return new Promise((resolve) => {
        this.invterval = setInterval(() => {
          this.count++;
          if (this.count == 30) {
            if (this.buffer.length < 1) return;
            let first = JSON.stringify(this.buffer[0]);
            let second = JSON.stringify(this.buffer[1]);
            if (first !== second) return;
            this.closeModal(resolve);
          }
          if (this.count >= 60 || this.close) {
            this.closeModal(resolve);
          }
        }, 100);
        setTimeout(resolve, 2000);
      });
    },
    closeModal(resolve) {
      this.count = 1;
      this.show = false;
      clearInterval(this.invterval);
      this.close = true;
      this.$store.commit("notifications/clearData");
      if (!resolve.type) setTimeout(resolve, 200);
    },
    success(val) {
      if (val === "Success") return this.$t("server.success");
      return val;
    },
  },
  computed: {
    ...mapState("notifications", ["notifications"]),
  },
  watch: {
    show() {
      if (!this.show) return;
    },
    notifications(val) {
      if (this.buffer.length > 0) return;
      this.buffer = val;
    },
    buffer() {
      this.process();
    },
  },
  mounted() {
    this.$store.commit("notifications/clearData");
  },
};
</script>

<style lang="less" scoped>
.notification {
  background-color: #47657fcb;
  width: 300px;
  min-height: 72px;
  position: fixed;
  right: 15px;
  top: 80px;
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  font-size: 13px;
  cursor: pointer;
  z-index: 8;
}

.red_border {
  border: 1px solid rgba(223, 81, 81, 0.55);
}

.green_border {
  border: 1px solid hsla(156, 41%, 42%, 0.5);
}

@media only screen and (max-width: 576px) {
  .notification {
    width: 100%;
    right: 0px;
    top: 0px;
    background-color: #47657f;
  }
}
</style>