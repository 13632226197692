<template>
  <div class="relative" ref="timePicker">
    <button type="button" class="d-flex w100" @click="showPicker">
      <div class="w100">
        <input
          type="text"
          @keydown.enter="showPicker"
          @keydown.prevent.space="showPicker"
          @keydown.esc="onBlur"
          data-lpignore="true"
          :style="inputStyling"
          ref="button"
          v-model="selected"
        />
        <div class="line" ref="line"></div>
      </div>
      <button
        v-if="noedit"
        tabindex="-1"
        type="button"
        class="box center"
        :style="boxStyling"
      >
        <s-icon height="16" color="grey">clock-outline</s-icon>
      </button>
    </button>

    <transition name="dropdown">
      <div v-show="show" class="clockContainer" v-hotkey="{ esc: onBlur }">
        <FocusLoop>
          <div class="time">
            <div class="d-flex pl-40">
              <button
                class="opacity-40 pointer"
                type="button"
                @click="
                  hours = true;
                  reset();
                "
                :class="{ selected: hours }"
              >
                {{ time.format("hh") }}
              </button>
              <div>:</div>
              <button
                class="opacity-40 pointer"
                type="button"
                @click="
                  hours = false;
                  reset();
                "
                :class="{ selected: !hours }"
              >
                {{ time.format("mm") }}
              </button>
            </div>
            <div class="center d-col ml-10">
              <button
                class="font-18 opacity-40 pointer"
                type="button"
                @click="switchToAm()"
                :class="{ selected: am }"
              >
                AM
              </button>
              <button
                class="font-18 opacity-40 pointer"
                type="button"
                @click="switchToPm()"
                :class="{ selected: !am }"
              >
                PM
              </button>
            </div>
          </div>
          <div v-if="hours" class="center w100 h100">
            <div class="clock center noselect">
              <div class="hand"></div>
              <div class="dot"></div>
              <button
                v-for="index in 12"
                :key="index"
                type="button"
                @click="select(index * 30, $event.target, true)"
                :class="'time-' + index"
              >
                {{ index }}
              </button>
            </div>
          </div>
          <div v-if="!hours" class="center w100 h100">
            <div class="clock center noselect">
              <div class="hand"></div>
              <div class="dot"></div>
              <button
                v-for="index in 12"
                :key="index"
                type="button"
                @click="select(index * 30, $event.target, true)"
                :class="'time-' + index"
              >
                {{ (index * 5) % 60 }}
              </button>
            </div>
          </div>
        </FocusLoop>
      </div>
    </transition>
  </div>
</template>

<script>
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { mapState } from "vuex";
dayjs.extend(customParseFormat);
import localizedFormat from "dayjs/plugin/localizedFormat";
dayjs.extend(localizedFormat);
export default {
  props: ["height", "prefill", "noedit"],
  data() {
    return {
      time: null,
      show: false,
      hours: true,
      am: false,
      selected: null,
    };
  },
  methods: {
    switchToAm() {
      let time = this.time.subtract(12, "hour");
      if (!this.am) this.time = time;
      this.am = true;
    },
    switchToPm() {
      if (this.am) this.time = this.time.add(12, "hour");
      this.am = false;
    },
    onFocus() {
      this.$refs.line.style.width = "100%";
      this.$refs.button.focus();
    },
    onBlur() {
      this.show = false;
      this.$refs.line.style.width = "0";
      this.checkInputTime();
    },
    checkInputTime() {
      let time = dayjs(this.selected, "hh:mm A");
      let valid = time.isValid();
      if (!valid) this.selected = dayjs().format("hh:mm A");
      this.$refs.button.blur();
      let mod = this.modFive(this.selected);
      let sub = mod.substring(1);
      if (mod.charAt(0) == "0") return this.preSelectAtime(sub);
      this.preSelectAtime(mod);
    },
    modFive(val) {
      let time = dayjs(val, "hh:mm A");
      let mins = time.format("mm");
      let mod = Number(mins) % 5 == 0;
      let ceil = Math.ceil(mins / 5) * 5;
      time = time.minute(ceil);
      return time.format("hh:mm A");
    },
    listen(event) {
      if (!event) return;
      var el = this.$refs.timePicker;
      if (!el.contains(event.target)) {
        this.show = false;
        this.onBlur();
      }
    },
    showPicker() {
      if (!this.noedit) return;
      this.show = !this.show;
      if (this.show) this.onFocus();
      if (!this.show) this.onBlur();
    },
    escPicker() {
      if (this.noedit) return;
      if (this.show) this.onBlur();
      this.show = false;
    },
    select(val, el, final) {
      let time = this.time;
      if (!el) return;
      if (!this.hours) time = this.time.minute(el.innerHTML);
      if (this.hours) time = this.time.hour(el.innerHTML);
      let ampm = this.am ? " AM" : " PM";
      time = time.format("hh:mm").concat(ampm);
      time = dayjs(time + ampm, "hh:mm A");
      this.updateUI(val, el);
      this.time = time;

      if (final) this.$emit("changeTime", time);
    },
    updateUI(val, el) {
      var nodes = document.getElementsByClassName("clock")[0]?.childNodes;
      if (!nodes) return;
      for (var i = 0; i < nodes.length; i++) {
        nodes[i].classList.remove("active");
      }
      el.classList.add("active");
      let hand = document.getElementsByClassName("hand")[0];
      hand.style.transform = "rotate(" + val + "deg) scaleY(1)";
    },
    reset() {
      let nodes = document.getElementsByClassName("clock")[0].childNodes;
      for (let i = 0; i < nodes.length; i++) {
        nodes[i].classList.remove("active");
      }

      let current;
      let multiplier;

      if (this.hours) {
        multiplier = 1;
        current = this.time.format("h");
      } else {
        multiplier = 5;
        current = this.time.format("mm");
      }

      let hand = document.getElementsByClassName("hand")[0];
      hand.style.transform =
        "rotate(" + current * multiplier * 30 + "deg) scaleY(1)";

      if (!this.hours && current == 0) {
        current = 12;
        multiplier = 1;
      }

      let el = document.getElementsByClassName(
        "time-" + current / multiplier
      )[0];
      el.classList.add("active");
      if (!this.prefill) this.$emit("changeTime", this.time);
    },
    async preSelectAtime(time) {
      let amOrPm = time.split(" ")[1];
      let hour = time.split(" ")[0].split(":")[0];
      let mins = time.split(" ")[0].split(":")[1];
      let el_hours = document.getElementsByClassName("time-" + hour)[0];
      this.select(hour * 30, el_hours);
      this.hours = false;
      if (mins == "00") mins = 60;
      let el_mins = document.getElementsByClassName("time-" + mins / 5)[0];
      let deg = (mins / 5) * 30;
      await this.$wait();
      this.select(deg, el_mins);
      if (amOrPm == "AM") this.am = true;
      if (amOrPm == "PM") this.am = false;
      this.hours = true;
      await this.$wait();
      this.select(hour * 30, el_hours, true);
    },
  },
  computed: {
    inputStyling: function () {
      let ob = {};
      if (this.height) ob.height = this.height + "px";
      if (!this.noedit) ob.backgroundColor = "#00000000";
      if (this.show) ob.backgroundColor = "rgba(0, 0, 0, 0.45)";
      return ob;
    },
    boxStyling: function () {
      let ob = {};
      if (this.height) ob.height = this.height + "px";
      if (this.height) ob.minWidth = this.height + "px";
      if (this.show) ob.backgroundColor = "#000000";
      return ob;
    },
    ...mapState("user", ["lang"]),
    ...mapState("schedule", ["datetime"]),
  },
  watch: {
    am(val) {
      if (this.noedit) this.$emit("changeTime", this.time);
    },
    time(val) {
      this.selected = dayjs(val).format("hh:mm A");
    },
  },
  destroyed() {
    window.removeEventListener("click", this.listen);
  },
  created() {
    this.time = dayjs(this.prefill, "hh:mm A");

    if (this.time.format("A") == "AM") this.am = true;
  },
  mounted() {
    this.selected = dayjs().format("hh:mm A");
    this.reset();
    this.preSelectAtime(this.prefill);

    window.addEventListener("click", this.listen);
  },
};
</script>

<style scoped>
.clock > button {
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
}

.active {
  background-color: #409875;
}

.selected {
  opacity: 1;
}

.hand {
  height: calc(50% - 15px);
  width: 2px;
  bottom: 50%;
  left: calc(50% - 1px);
  transform-origin: center bottom;
  position: absolute;
  will-change: transform;
  z-index: 1;
  background-color: #409875;
}

.dot {
  position: absolute;
  bottom: 50%;
  transform: translate(0%, 50%);
  height: 5px;
  width: 5px;
  background-color: #409875;
  border-radius: 50%;
}

.time-12 {
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 0%;
}

.time-1 {
  position: absolute;
  transform: translate(-50%, -50%);
  left: 75%;
  top: 6.69873%;
  transform: translate(-50%, -50%);
}

.time-2 {
  position: absolute;
  transform: translate(-50%, -50%);
  left: 93.3013%;
  top: 25%;
}

.time-3 {
  position: absolute;
  transform: translate(-50%, -50%);
  left: 100%;
  top: 50%;
}

.time-4 {
  position: absolute;
  transform: translate(-50%, -50%);
  left: 93.3013%;
  top: 75%;
}

.time-5 {
  position: absolute;
  transform: translate(-50%, -50%);
  left: 75%;
  top: 93.3013%;
}

.time-6 {
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 100%;
}

.time-7 {
  position: absolute;
  transform: translate(-50%, -50%);
  left: 25%;
  top: 93.3013%;
}

.time-8 {
  position: absolute;
  transform: translate(-50%, -50%);
  left: 6.69873%;
  top: 75%;
}

.time-9 {
  position: absolute;
  transform: translate(-50%, -50%);
  left: 0%;
  top: 50%;
}

.time-10 {
  position: absolute;
  transform: translate(-50%, -50%);
  left: 6.69873%;
  top: 25%;
}

.time-11 {
  position: absolute;
  transform: translate(-50%, -50%);
  left: 25%;
  top: 6.69873%;
}

.clockContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 290px;
  height: 310px;
  border-radius: 2px;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.35);
  background-color: #243748;
  position: absolute;
  z-index: 1;
  right: 0;
}

.clock {
  background-color: #3a4b5a;
  width: 180px;
  height: 180px;
  border-radius: 50%;
  position: relative;
  border: 25px solid #3a4b5a;
}

.box {
  min-width: 30px;
  min-height: 30px;
  width: 30px;
  height: 30px;
  background-color: #151b23;
  margin-left: 2px;
}

.time {
  display: flex;
  justify-content: center;
  font-size: 46px;
  font-weight: 500;
  width: 100%;
  height: 60px;
  position: relative;
  top: 10px;
  padding-bottom: 10px;
}

input {
  height: 30px;
  background-color: rgba(0, 0, 0, 0.35);
  font-size: 14px;
  color: rgba(255, 255, 255, 0.75);
  padding: 8px;
  width: 100%;
  min-width: 75px;
  box-sizing: border-box;
  cursor: pointer;
}

input:hover {
  background-color: rgba(0, 0, 0, 0.45);
}

input:focus {
  background-color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  color: rgba(255, 255, 255, 0.95);
  outline: none;
}

.line {
  width: 0;
  height: 1px;
  background-color: #409875;
  transition: all ease 0.2s;
}

input {
  height: 30px;
  background-color: rgba(0, 0, 0, 0.35);
  font-size: 14px;
  color: rgba(255, 255, 255, 0.75);
  padding: 8px;
  width: 100%;
  box-sizing: border-box;
  cursor: pointer;
  /* caret-color: transparent; */
  user-select: none;
}

input:hover {
  background-color: rgba(0, 0, 0, 0.45);
}

input:focus {
  background-color: rgba(0, 0, 0, 0.45);
  color: rgba(255, 255, 255, 0.95);
  font-size: 14px;
  outline: none;
}

input::selection {
  background: none;
}

input::selection {
  background: none;
}

button:focus {
  font-weight: 600;
}

@media only screen and (max-width: 576px) {
  .clockContainer {
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>