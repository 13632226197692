<template>
  <FocusLoop>
    <div ref="modal_page" class="meeting_modal_page center wrap">
      <div class="account_modal" ref="modal_window">
        <div class="header">
          <div></div>
          <div class="center">{{ $t("modal.newMeeting.start") }}</div>
          <s-icon @click.native="close" color="grey">close</s-icon>
        </div>
        <hr />

        <div v-show="page1" class="body center d-col">
          <div class="font-18">{{ $t("modal.newMeeting.one") }}</div>
          <s-dropdown class="w70" :height='40' v-model="room" :items="roomsItems"></s-dropdown>
          <div class="font-18">{{ $t("modal.newMeeting.two") }}</div>
          <radio v-model="option" class="px-20" />
          <s-text-field
            v-model="password"
            :placeholder="placeholder"
            :disabled="disabled"
            height="40"
            bar
            class="w70"
          ></s-text-field>
          <s-btn @click.native="next" height="36" class="green w50">{{
            $t("modal.newMeeting.next")
          }}</s-btn>
        </div>

        <div v-show="!page1" class="">
          <div class="body center d-col">
            <div class="font-20">{{ $t("modal.newMeeting.ready") }}</div>
            <div class="d-flex">
              <div class="font-18 opacity-60 pr-5">
                {{ fullLink | splice }}
              </div>
              <s-icon
                @click.native="CopyToClipboard"
                height="20"
                color="grey"
                class="pointer"
                >content-copy</s-icon
              >
            </div>
            <div class="share opacity-60 font-14 text-center">
              {{ $t("modal.newMeeting.share") }}
            </div>
            <s-btn @click.native="join" height="40" width="210" class="green">
              {{ $t("modal.newMeeting.join") }}</s-btn
            >
          </div>
          <hr />
          <div class="center d-col pb-30 pt-20 invitation">
            <div class="font-18 mb-10">{{ $t("modal.newMeeting.invite") }}</div>
            <div
              class="font-12 missingText"
              :class="{ 'red--text': error, 'green--text': !error }"
            >
              {{ message }}
            </div>
            <div class="d-flex w80">
              <s-text-field
                :placeholder="$t('modal.newMeeting.enter')"
                block
                v-model="email"
              ></s-text-field>
              <s-btn @click.native="invite" class="green" height="40">
                {{ $t("modal.newMeeting.send") }}</s-btn
              >
            </div>
          </div>
        </div>
      </div>

      <div
        style="color: black; background-color: #ffffff"
        class="clipboard"
        ref="clipboard"
      >
        <div class="" style="color: #56bc93; font-size: 18px">SecurMeet</div>
        <div class="">
          <strong>{{ firstName }}</strong> has invited you to attend a SecurMeet
          Meeting
        </div>
        <div style="display: flex" class="">
          Link:
          <a :href="' https://' + meetServer + '/' + link">{{
            "https://" + this.meetServer + "/" + this.link
          }}</a>
        </div>
      </div>
    </div>
  </FocusLoop>
</template>

<script>
import radio from "../Schedule/Radio";
import { gsap } from "gsap";
import { mapState } from "vuex";

export default {
  data() {
    return {
      page1: true,
      password: "",
      disabled: true,
      option: "one",
      room: null,
      roomName: null,
      link: "",
      email: "",
      placeholder: this.$t("modal.newMeeting.password"),
      message: "",
      error: false,
    };
  },
  components: {
    radio,
  },
  methods: {
    close() {
      gsap.to(this.$refs.modal_page, {
        opacity: 0,
        duration: 0.3,
        ease: "power2.out",
      });
      gsap.to(this.$refs.modal_window, {
        scale: 0.7,
        duration: 0.3,
        ease: "power2.out",
        onComplete: () => this.$store.commit("modals/meetingModal", false),
      });
    },
    next() {
      this.page1 = false;

      for (let x of this.roomsItems) {
        if (x.id === this.room.id)
          this.$store.commit("rooms/setRoomName", x.title);
      }
    },
    async copy() {
      const aioColors = document.querySelectorAll(".clipboard > div");

      aioColors.forEach((color) => {
        color.addEventListener("click", () => {
          const selection = window.getSelection();
          const range = document.createRange();
          range.selectNodeContents(color);
          selection.removeAllRanges();
          selection.addRange(range);

          try {
            document.execCommand("copy");
            selection.removeAllRanges();

            const original = color.textContent;
            color.textContent = "Copied!";
            color.classList.add("success");

            setTimeout(() => {
              color.textContent = original;
              color.classList.remove("success");
            }, 1200);
          } catch (e) {
            const errorMsg = document.querySelector(".error-msg");
            errorMsg.classList.add("show");

            setTimeout(() => {
              errorMsg.classList.remove("show");
            }, 1200);
          }
        });
      });
    },
    async invite() {
      if (!this.validate()) return;
      let url = "/api/invite/" + this.link + "/" + this.email;
      let response = await this.$store.dispatch("auth/invite", url);

      let message = this.$t("modal.newMeeting.email_sent") + this.email;
      this.email = "";
      if (response.status === "ok") return (this.message = message);
    },
    validate() {
      if (this.email === "") {
        this.message = this.$t("modal.newMeeting.enter_valid_email");
        this.error = true;
        return false;
      }

      var regexp =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      if (!regexp.test(String(this.email).toLowerCase())) {
        this.message = this.$t("modal.newMeeting.enter_valid_email");
        this.error = true;
        return false;
      }
      this.message = "";
      this.error = false;
      return true;
    },
    generatePassword() {
      let length = 16,
        charset =
          "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*",
        retVal = "";
      for (let i = 0, n = charset.length; i < length; ++i) {
        retVal += charset.charAt(Math.floor(Math.random() * n));
      }
      return retVal;
    },
    async generateLink() {
      let url = "/api/room/" + this.room.id;
      let response = await this.$store.dispatch("auth/room", url);
      this.link = response.url;
      this.$store.commit("user/activeRoom", response.url);
    },
    join() {
      this.$store.commit("modals/meetingModal", false);
      this.$store.dispatch('rooms/startMeeting', this.room.id)
      this.$store.dispatch('user/startMeeting', this.room.id)
      window.open(this.fullLink);
      // this.$router.push("/now/" + this.link);
    },
    copyToClip(str) {
      function listener(e) {
        e.clipboardData.setData("text/html", str);
        e.clipboardData.setData("text/plain", str);
        e.preventDefault();
      }
      document.addEventListener("copy", listener);
      document.execCommand("copy");
      document.removeEventListener("copy", listener);
    },
    CopyToClipboard() {
      this.$store.commit("notifications/push", {
        success: this.$t("modal.newMeeting.clipboard"),
      });

      var doc = document,
        text = this.$refs.clipboard,
        range,
        selection;

      if (doc.body.createTextRange) {
        range = doc.body.createTextRange();
        range.moveToElementText(text);
        range.select();
      } else if (window.getSelection) {
        selection = window.getSelection();
        range = doc.createRange();
        range.selectNodeContents(text);
        selection.removeAllRanges();
        selection.addRange(range);
      }
      document.execCommand("copy");
      window.getSelection().removeAllRanges();
    },
  },

  filters: {
    splice(val) {
      if (val === "") return;
      if (val.length > 32) return val.slice(0, 32) + "...";
      return val;
    },
  },
  computed: {
    ...mapState("user", ["firstName", "meetServer"]),
    rooms() {
      return this.$store.state.rooms.rooms;
    },
    fullLink() {
      return "https://" + this.meetServer + "/" + this.link;
    },
    userEmail() {
      return this.$store.state.user.email;
    },
    roomsItems() {
      let data = this.$store.state.rooms.rooms;
      for (let x of data) {
        x.display = x.title;
      }
      return data.filter((el) => !el.isDeleted);
    },
  },
  watch: {
    option(val) {
      if (val === "one") {
        this.password = "";
        this.disabled = true;
        this.placeholder = this.$t("modal.newMeeting.not_protected");
      }
      if (val === "two") {
        this.password = "";
        this.disabled = false;
        this.placeholder = this.$t("modal.newMeeting.enter_password");
      }
      if (val === "three") {
        this.password = this.generatePassword();
        this.disabled = true;
      }
    },
    room(val) {
      this.generateLink();
    },
  },
  mounted() {
    this.generateLink();

    gsap.from(this.$refs.modal_page, {
      opacity: 0,
      duration: 0.3,
      ease: "power2.out",
    });

    gsap.from(this.$refs.modal_window, {
      scale: 0.7,
      duration: 0.3,
      ease: "power2.out",
    });
  },
};
</script>

<style scoped>
.meeting_modal_page {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 101vh;
  background: rgba(41, 41, 41, 0.6);
  z-index: 8;
}

.account_modal {
  width: 100%;
  min-height: 475px;
  max-width: 600px;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.35);
  background-color: #243748;
}

.header {
  display: grid;
  grid-template-columns: 20px auto 20px;
  padding: 20px;
  font-size: 20px;
  background-color: #203140;
}

.body {
  padding-top: 30px;
  padding-bottom: 30px;
  grid-row-gap: 30px;
  min-height: 202px;
}

.share {
  max-width: 331px;
}

.invitation {
  background-color: #203140;
}

.clipboard {
  position: absolute;
  left: 9999999px;
}
</style>
