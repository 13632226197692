<template>
  <section class="modalContainer">
    <Meeting v-if="meeting" />
    <Drawer />
    <Billing v-if="billing" />
    <Attendee v-if="attendee" />
    <Room v-if="room" />
    <Timezone v-if="timezone" />
    <Repeating v-if="repeating" />
    <DeleteEventConfirmation v-if="deleteEventConfirmation" />
    <DeleteAttendeeConfirmation v-if="deleteAttendeeConfirmation" />
    <DeleteRoomConfirmation v-if="deleteRoomConfirmation" />
    <UpdateAttendeeConfirmation v-if="updateAttendeeConfirmation" />
    <UpdateRoomsConfirmation v-if="updateRoomsConfirmation" />
    <FirstTimeWizard v-if="firstTimeWizard" />
    <Notifications />
    <SaveEventConfirmation v-if="saveEventConfirmation" />
    <DeleteEventSimpleConfirmation v-if="deleteEventSimpleConfirmation" />
    <AddGroup v-if="addGroup" />
    <AppInfo v-if="appInfo" />
  </section>
</template>

<script>
import { mapState } from "vuex";
import Meeting from "@/components/Modals/NewMeeting";
import Attendee from "@/components/Modals/AddAttendee";
import AddGroup from "@/components/Modals/AddGroup";
import Room from "@/components/Modals/AddRoom";
import Billing from "@/components/Modals/Billing";
import Timezone from "@/components/Modals/Timezone";
import Repeating from "@/components/Modals/Repeating";
import Drawer from "@/components/Toolbars/Drawer";
import DeleteEventConfirmation from "@/components/Modals/DeleteEventConfirmation";
import DeleteAttendeeConfirmation from "@/components/Modals/DeleteAttendeeConfirmation";
import DeleteRoomConfirmation from "@/components/Modals/DeleteRoomConfirmation";
import FirstTimeWizard from "@/components/Modals/FirstTimeWizard";
import UpdateAttendeeConfirmation from "@/components/Modals/UpdateAttendeeConfirmation";
import UpdateRoomsConfirmation from "@/components/Modals/UpdateRoomsConfirmation";
import Notifications from "@/components/Modals/Notifications";
import SaveEventConfirmation from "@/components/Modals/SaveEventConfirmation";
import DeleteEventSimpleConfirmation from "@/components/Modals/DeleteEventSimpleConfirmation";
import Tutorial from "@/components/Tutorial/Tutorial";
import AppInfo from "@/components/Modals/AppInfo.vue";
export default {
  components: {
    Meeting,
    Drawer,
    Billing,
    Attendee,
    AddGroup,
    Room,
    Timezone,
    Repeating,
    DeleteEventConfirmation,
    DeleteAttendeeConfirmation,
    DeleteRoomConfirmation,
    FirstTimeWizard,
    UpdateAttendeeConfirmation,
    UpdateRoomsConfirmation,
    Notifications,
    SaveEventConfirmation,
    DeleteEventSimpleConfirmation,
    Tutorial,
    AppInfo,
  },
  computed: {
    ...mapState("modals", [
      "meeting",
      "billing",
      "attendee",
      "addGroup",
      "room",
      "timezone",
      "repeating",
      "deleteEventConfirmation",
      "deleteRoomConfirmation",
      "deleteAttendeeConfirmation",
      "firstTimeWizard",
      "updateAttendeeConfirmation",
      "updateRoomsConfirmation",
      "Notifications",
      "rateExperience",
      "saveEventConfirmation",
      "deleteEventSimpleConfirmation",
      "appInfo",
    ]),
  },
};
</script>