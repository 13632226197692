<template>
  <Modal>
    <form @submit.prevent="post" class="col gap-50">
      <section class="header row space-between align-center">
        <span class="font-18">
          {{ action }} {{ $t("modal.addGroup.title") }}
        </span>
        <button @click="close" class="center">
          <s-icon color="grey">close</s-icon>
        </button>
      </section>
      <section class="body col gap-20">
        <div
          class="center red--text missingText font-14"
          :class="{ 'green--text': message.status == 'ok' }"
        >
          {{ message }}
        </div>
        <div class="grid align-center">
          <div class="font-14 opacity-60">{{ $t("modal.addGroup.name") }}</div>
          <s-text-field autofocus v-model="name" ref="name"></s-text-field>
          <div class="font-14 opacity-60">
            {{ $t("modal.addGroup.attendees") }}
          </div>
          <s-dropdown
            :height="40"
            :items="participants"
            @multiSelect="syncEmails"
            multi
          ></s-dropdown>
        </div>
        <div class="opacity-60 text-center">
          {{ $t("modal.addGroup.color") }}
        </div>
        <div class="w100 d-flex wrap center">
          <button
            type="button"
            v-for="item in 20"
            :key="item"
            class="color"
            @click="selectColor(item)"
            :class="{ active: item == color }"
            :style="{ backgroundColor: 'var(--sm-color-' + item + ')' }"
          ></button>
        </div>
        <div class="wrapper gap-10 col">
          <div class="items center" v-if="emails.length == 0">
            {{ $t("modal.addGroup.no_attendee") }}
          </div>
          <div
            v-for="(item, index) in emails"
            :key="index"
            class="items align-center space-between"
          >
            <span> {{ index + 1 }}. {{ item.display }}</span>
            <button type="button" class="center close" @click="del(index)">
              <s-icon height="16" color="white">close</s-icon>
            </button>
          </div>
        </div>
      </section>
      <section class="footer row gap-20 justify-end">
        <s-btn height="36" @click.native="close" type="button">
          {{ $t("modal.cancel") }}
        </s-btn>
        <s-btn height="36" class="green" :loading="loading">
          {{ $t("modal.save") }}
        </s-btn>
      </section>
    </form>
  </Modal>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      name: "",
      color: 1,
      participants: [],
      emails: [],
      loading: false,
      message: "",
      id: "",
    };
  },
  methods: {
    async post() {
      let ids = this.emails.map((a) => a.value);
      //ids = JSON.stringify(ids);
      let data = {
        name: this.name,
        color: this.color,
        attendees: ids,
        id: this.id,
      };
      console.log("🚀 ~ data", data)
      let method = this.edit ? "updateGroups" : "postGroup";
      this.loading = true;
      let res = await this.$store.dispatch(`attendees/${method}`, data);
      this.loading = false;
      this.$store.dispatch("attendees/getGroups");
      if (res.status !== "error") return this.close();
      this.message = this.$t(`server.${res.errorCode}`);
    },
    close() {
      this.$store.commit("modals/addGroup", false);
    },
    selectColor(val) {
      this.color = val;
    },
    del(index) {
      this.emails.splice(index, 1);
      this.participants[index].selected = false;
    },
    syncEmails(val) {
      this.emails = val;
    },
    getAttendeesByEmail(val) {
      let attendees = this.$store.state.attendees.attendees;
      let selected = val;
      let res = [];
      if (typeof val !== "object") selected = JSON.parse(val);
      for (let x of selected) {
        let item = attendees.find((el) => el.email == x);
        res.push({ display: item.email, value: item.email, selected: true });
      }
      return res;
    },
    populateDropdown() {
      this.participants = [];
      for (let x of this.attendees) {
        let res = this.emails.find((el) => el.value == x.email);
        let selected = res ? true : false;
        let data = { display: x.email, value: x.email, selected: selected };
        this.participants.push(data);
        console.log("🚀 ~ data", data)
      }
    },
  },
  computed: {
    action() {
      return this.edit
        ? this.$t("modal.addGroup.edit")
        : this.$t("modal.addGroup.create");
    },
    ...mapState("attendees", ["attendees", "edit"]),
  },
  mounted() {
    if (this.edit) {
      this.name = this.edit.name;
      this.color = this.edit.color;
      this.emails = this.getAttendeesByEmail(this.edit.attendees);
      this.id = this.edit.id;
    }
    this.populateDropdown();
  },
};
</script>

<style scoped>
.grid {
  display: grid;
  grid-template-columns: 150px auto;
  grid-row-gap: 15px;
}

.items {
  font-size: 14px;
  color: #74b7e4;
  justify-content: space-between;
  max-width: 460px;
}

.wrapper {
  height: 150px;
  overflow: auto;
  border: 1px solid hsla(156, 41%, 42%, 0.2);
  box-sizing: border-box;
  background-color: #17242f;
  padding: 10px;
}

.color {
  height: 32px;
  width: 32px;
  border-radius: 50%;
  margin: 5px;
  border: 1px solid rgba(0, 0, 0, 0);
}

.color:hover {
  box-shadow: 0 0 12px var(--green);
}

.active {
  box-shadow: 0 0 20px var(--green);
  border: 1px solid rgb(255, 255, 255);
}
</style>