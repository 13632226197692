<template>
  <FocusLoop>
    <div class="modal_page center">
      <form
        @submit.prevent="post"
        class="modal_window d-flex d-col space-between"
      >
        <div class="d-flex space-between align-center">
          <div class="font-18">{{ $t("modal.custom.title") }}</div>
          <button type="button" class="center" @click="closeModal">
            <s-icon height="16" color="white">close</s-icon>
          </button>
        </div>
        <div class="mt-30">
          <s-dropdown
            :height="40"
            v-model="repeatingValue"
            :items="data"
          ></s-dropdown>
        </div>
        <div class="d-flex justify-end mt-40">
          <s-btn
            @click.native="closeModal"
            type="button"
            height="36"
            class="mr-10"
          >
            {{ $t("modal.cancel") }}
          </s-btn>
          <s-btn type="submit" height="36" class="green">
            {{ $t("modal.save") }}</s-btn
          >
        </div>
      </form>
    </div>
  </FocusLoop>
</template>

<script>
import { mapState } from "vuex";
import { gsap } from "gsap";
export default {
  data() {
    return {
      repeatingValue: "",
      data: [
        {
          display: this.$t("modal.custom.no_repeat"),
          value: "none",
          selected: true,
        },
        {
          display: this.$t("modal.custom.daily"),
          value: "daily",
          selected: false,
        },
        {
          display: this.$t("modal.custom.weekly"),
          value: "weekly",
          selected: false,
        },
        {
          display: this.$t("modal.custom.monthly"),
          value: "monthly",
          selected: false,
        },
        {
          display: this.$t("modal.custom.annually"),
          value: "annually",
          selected: false,
        },
        {
          display: this.$t("modal.custom.everyday"),
          value: "workdays",
          selected: false,
        },
      ],
    };
  },
  methods: {
    post() {
      this.$store.commit("schedule/setRepeating", this.repeatingValue.value);
      this.closeModal();
    },
    closeModal() {
      gsap.to(".modal_page", {
        opacity: 0,
        duration: 0.3,
        ease: "power2.out",
      });
      gsap.to(".modal_window", {
        scale: 0.7,
        duration: 0.3,
        ease: "power2.out",
        onComplete: () => this.$store.commit("modals/repeatingModal", false),
      });
    },
  },
  computed: {
    ...mapState("schedule", ["repeating"]),
  },
  mounted() {
    if (!this.repeating) return;
    this.data.forEach((val) => (val.selected = false));
    let active = this.data.filter((val) => val.value == this.repeating)[0];
    if (this.repeating) active.selected = true;

    gsap.from(".modal_page", {
      opacity: 0,
      duration: 0.3,
      ease: "power2.out",
    });

    gsap.from(".modal_window", {
      scale: 0.7,
      duration: 0.3,
      ease: "power2.out",
    });
  },
};
</script>

<style scoped>
.modal_page {
  position: fixed;
  left: 0;
  top: 0px;
  width: 100vw;
  height: 100vh;
  background: rgba(41, 41, 41, 0.6);
  z-index: 8;
}

.modal_window {
  max-width: 450px;
  width: 100%;
  padding: 30px;
  padding-left: 60px;
  padding-right: 60px;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.35);
  background-color: #243748;
}
</style>