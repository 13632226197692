import { get, post } from "../assets/functions";
export default {
  namespaced: true,
  state: {
    payment: {},
  },
  mutations: {
    payment(state, response) {
      state.payment = response;
    },
  },
  actions: {
    async getKey(context) {
      return await get("/stripe/key");
    },
    async getBilling(context) {
      let response = await get("/billing/payment");
      context.commit("payment", response);
    },
    async getBillingUpgrade(context) {
      let response = await get("/billing/upgrade");
      context.commit("payment", response);
      return response;
    },
    async getUpgradeSecret(context, data) {
      return await get("/billing/upgrade/secret");
    },
    async postFreeToPro(context, data) {
      return await post("/billing/payment", data);
    },
    async addMoreRoomsPro(context, data) {
      return await post("/billing/upgrade/setup", { rooms: data });
    },
    async paymentIntent(context, data) {
      return await post("/billing/payment/secret", { rooms: data });
    },
    async finishUpgrade(context, data) {
      return await post("/billing/upgrade/finish", { paymentIntent: data });
    },
    async downgrade(context, data) {
      return await post("/billing/downgrade", { rooms: data });
    },
  },
};
