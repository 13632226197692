import { del, get, post } from "../assets/functions";
import store from "./index";
export default {
  namespaced: true,
  state: {
    rooms: [],
    name: null,
    edit: null,
  },
  mutations: {
    setData(state, response) {
      state.rooms = response;
    },
    setRoomName(state, response) {
      state.name = response;
    },
    setEditRoomName(state, response) {
      state.edit.title = response;
    },
    editRoom(state, response) {
      state.edit = response;
    },
    editColor(state, response) {
      state.edit.color = response;
    },
    clearRoom(state) {
      state.edit = null;
    },
  },
  actions: {
    async getRoomsInformation(context) {
      let response = await get("/api/rooms");
      store.dispatch("user/getUserInformation");
      context.commit("setData", response);
      return response;
    },
    async postRoom(context, data) {
      let response = await post("/api/room", data);
      store.commit("notifications/raw", response);
      await context.dispatch("getRoomsInformation");
      return response;
    },
    async updateRoom(context, data) {
      if (!data) data = context.state.edit;
      let response = await post("/api/room/update", data);
      store.commit("notifications/raw", response);
      await context.dispatch("getRoomsInformation");
      return response;
    },
    async deleteRoom(context, id) {
      if (!id) id = context.state.edit.id;
      let response = await del("/api/room/" + id);
      store.commit("notifications/raw", response);
      await context.dispatch("getRoomsInformation");
      return response;
    },
    async startMeeting(context, id) {
      return await get("/api/room/" + id);
    },
  },
};
