import { get, post } from "../assets/functions";
import store from "../store/index";
import router from "../router";
export default {
  namespaced: true,
  state: {
    email: "",
    firstName: "",
    lastName: "",
    link: "",
    timezones: [],
    userTimezone: "",
    entity_rooms: "",
    entity_currency: "",
    entity_account_type: "",
    eventMaximumAttendees: "",
    entity_stripe_next_billing: {},
    numberOfRooms: "",
    productPrice: "",
    registrationDate: "",
    meetServer: "",
    isTrial: false,
    location: {},
    lang: "en",
    preferredRegion: "",
    tip: true,
    avatar: '',
    routerHistory: [],
    onpremise: false,
    role: "",
  },
  mutations: {
    setUser(state, response) {
      let tip = response.settings.tooltips == "yes" ? true : false;
      state.email = response.email;
      state.isTrial = response.isTrial;
      state.firstName = response.firstName;
      state.lastName = response.lastName;
      state.userTimezone = response.settings.timezone;
      state.numberOfRooms = response.numberOfRooms;
      state.tip = tip;
      state.meetServer = response.settings.meetServer;
      state.preferredRegion = response.settings.preferredRegion;
      state.registrationDate = response.registrationDate;
      state.avatar = response.avatar;
      state.role = response.role;
      state.entity_rooms = response.entity?.rooms;
      state.entity_currency = response.entity?.currency;
      state.entity_account_type = response.entity?.accountType;
      state.entity_stripe_next_billing = response.entity?.stripe?.nextBilling;
    },
    clear(state) {
      state.email = "";
      state.firstName = "";
      state.lastName = "";
    },
    activeRoom(state, val) {
      state.link = val;
    },
    setLang(state, val) {
      state.lang = val;
    },
    config(state, val) {
      state.productPrice = val.product_price;
      state.eventMaximumAttendees = Number(2);
    },
    tip(state, val) {
      state.tip = val;
    },
    onpremise(state, val) {
     state.onpremise = val;
    },
    getTimezones(state, response) {
      state.timezones = response;
    },
    userTimezones(state, response) {
      state.userTimezone = response;
    },
    setLocation(state, response) {
      state.location = response;
    },
    routerHistory(state, response) {
      let history = state.routerHistory;
      if (!history || history.length === 0) return state.routerHistory.push(response);
      if (history.length > 10) state.routerHistory.shift();
      state.routerHistory.push(response);
    },
  },
  actions: {
    async logout(context) {
      await get("/logout");
      return context.commit("clear");
    },
    async register(context, data) {
      return await post("/register", data);
    },
    async getUserInformation(context, data) {
      let response = await get("/api/me");
      await context.dispatch("getLocation");
      context.commit("setUser", response);
      if (!data?.lang) context.commit("setLang", response.settings.lang);
      return response;
    },
    async getTimezones(context) {
      let response = await get("/api/timezone");
      context.commit("getTimezones", response);
    },
    async getConfiguration(context) {
      let response = await get("/api/configuration");
      context.commit("config", response);
    },
    async setProfile(context, data) {
      if (!data.firstName) data.firstName = context.state.firstName;
      if (!data.lastName) data.lastName = context.state.lastName;
      let response = await post("/api/me", data);
      await context.dispatch("getUserInformation");
      return response;
    },
    async experienceRating(context, data) {
      return await post("/api/rate", { rating: data });
    },
    async setLang(context, data) {
      if (context.state.email == "") return;
      let body = {
        lang: data,
        firstName: context.state.firstName,
        lastName: context.state.lastName,
        timezone: context.state.userTimezone,
      };
      let response = await post("/api/me", body);
      return response;
    },
    async forgotPassword(context, data) {
      return await post("/api/password", { email: data });
    },
    async resetPassword(context, data) {
      return await post("/api/password/reset", { password: data });
    },
    async validateLink(context, data) {
      return await get("/api/password/" + data);
    },
    async startMeeting(context, data) {
      return await get("/api/startmeeting/" + data);
    },
    async getLocation(context) {
      let response = await get("/api/location");
      context.commit("setLocation", response);
      return response;
    },
    async setLangGuest(context, data) {
      return await post("/api/lang", { lang: data });
    },
    async getLangGuest(context, data) {
      let response = await get("/api/lang");
      if (!response?.lang) response.lang = "en";
      return response;
    },
    isNewRoomValid(context) {
      if (context.state.onpremise) return true;
      let limit = context.state.entity_rooms;
      let current = context.state.numberOfRooms;
      return current < limit;
    },
    async setProfilePicture(context, data) {
     let  url = process.env.VUE_APP_URL + '/api/avatar';
      let response = await fetch(url, {
        method: "POST",
        body: data,
        credentials: "include",
      });
      return await response.json();
    },
    async checkIfAccountIsOutOfTrial(context) {
      if (context.state.onpremise) return;
      if (context.state.role !== 'admin') return;
      let isFree = context.state.entity_account_type == "free";
      let isTrial = context.state.isTrial;
      if (!isFree) return;
      if (isFree && isTrial) return;
      store.commit("modals/billingModal", false);
    },
  },
};
