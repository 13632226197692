<template>
  <FocusLoop>
    <div class="modal_page center">
      <form
        @submit.prevent="post"
        class="modal_window d-flex d-col space-between"
      >
        <div class="d-flex space-between align-center">
          <div class="font-18">
            {{ action }} {{ $t("modal.addAttendee.attendee") }}
          </div>
          <s-icon @click.native="closeModal" color="grey">close</s-icon>
        </div>
        <div
          class="center mt-10 red--text missingText font-14"
          :class="{ 'green--text': message.status == 'ok' }"
        >
          {{ message.msg }}
        </div>




        <div class="grid align-center mt-20">
          <div class="font-14 opacity-60">
            {{ $t("modal.addAttendee.name") }}
          </div>
          <s-text-field autofocus v-model="name" ref="name"></s-text-field>
          <div class="font-14 opacity-60">
            {{ $t("modal.addAttendee.email") }}
          </div>
          <s-text-field v-model="email" :error="error"></s-text-field>
          <div class="font-14 opacity-60">
            {{ $t("modal.addAttendee.phone") }}
          </div>
          <s-text-field v-model="phone"></s-text-field>
          <div class="font-14 opacity-60">
            {{ $t("modal.addAttendee.timezone") }}
          </div>
          <s-timezone
            :height="40"
            :prefill="edit && edit.timezone"
            v-model="timezone"
          ></s-timezone>
          <div class="font-14 opacity-60">
            {{ $t("modal.addAttendee.language") }}
          </div>
          <s-dropdown
            :height="40"
            class="w100"
            v-model="language"
            :items="items"
          ></s-dropdown>
        </div>


        <div class="opacity-60 mt-20 mb-20 text-center">
          {{ $t("modal.addAttendee.color") }}
        </div>
        <div class="w100 d-flex wrap center">
          <button
            type="button"
            v-for="item in 20"
            :key="item"
            class="color"
            @click="select(item)"
            :class="{ active: item == selected }"
            :style="{ backgroundColor: 'var(--sm-color-' + item + ')' }"
          ></button>
        </div>
        <div class="d-flex mt-40 justify-end">
          <div
            v-if="!edit"
            class="center mr-auto pointer noselect"
            @click="check = !check"
          >
            <s-checkbox v-model="check"></s-checkbox>
            <span class="font-14 ml-10">
              {{ $t("modal.keep_open") }}
            </span>
          </div>
          <s-btn
            @click.native="closeModal"
            type="button"
            height="36"
            class="mr-10"
            >{{ $t("modal.cancel") }}</s-btn
          >
          <s-btn
            @click.native="del"
            v-if="edit"
            type="button"
            height="36"
            class="red mr-10"
            >{{ $t("modal.delete") }}</s-btn
          >
          <s-btn :disabled="block" type="submit" height="36" class="green">
            {{ $t("modal.save") }}
          </s-btn>
        </div>
      </form>
    </div>
  </FocusLoop>
</template>

<script>
import { mapState } from "vuex";
import { gsap } from "gsap";
export default {
  data() {
    return {
      name: "",
      email: "",
      phone: "",
      language: "",
      timezone: "",
      selected: 1,
      message: "",
      block: false,
      error: "",
      check: false,
    };
  },
  methods: {
    async post() {
      this.error = "";
      let res;
      let data = {
        name: this.name,
        email: this.email,
        phone: this.phone,
        color: this.selected,
        lang: this.language,
        timezone: this.timezone,
      };

      if (!this.validateEmail())
        return (this.error = this.$t("modal.addAttendee.valid"));

      if (this.edit) {
        this.block = true;
        data.id = this.edit.id;
        if (data.lang) data.lang = data.lang.value;

        res = await this.$store.dispatch("attendees/updateAttendees", data);
        if (res.status == "error") {
          this.message = {
            msg: this.$t(`server.${res.errorCode}`),
            status: res.status,
          };
        }
        if (res.status == "ok") return this.closeModal();
        this.block = false;
      } else {
        this.block = true;
        if (data.lang) data.lang = data.lang.value;
        res = await this.$store.dispatch("attendees/postAttendee", data);

        this.block = false;
        if (res.status == "error") {
          this.message = {
            msg: this.$t(`server.${res.errorCode}`),
            status: res.status,
          };
        } else {
          this.message = {
            msg: `${this.email} ` + this.$t(`modal.addAttendee.successfully`),
            status: "ok",
          };
          if (!this.check) return this.closeModal();
        }
      }
      this.$store.dispatch("attendees/getAttendeesInformation");
      if (res.status == "ok") {
        this.name = "";
        this.email = "";
        this.phone = "";
        this.selected = 1;
        this.$refs.name.onFocus();
      }
    },
    select(val) {
      this.selected = val;
    },
    async del() {
      gsap.to(".modal_page", {
        opacity: 0,
        duration: 0.3,
        ease: "power2.out",
      });
      gsap.to(".modal_window", {
        scale: 0.7,
        duration: 0.3,
        ease: "power2.out",
        onComplete: () => {
          this.$store.commit("modals/attendeeModal", false);
          this.$store.commit("modals/deleteAttendeeConfirmationModal", true);
          this.block = false;
        },
      });
    },
    validateEmail() {
      var regex =
        /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
      return regex.test(this.email);
    },
    closeModal() {
      gsap.to(".modal_page", {
        opacity: 0,
        duration: 0.3,
        ease: "power2.out",
      });
      gsap.to(".modal_window", {
        scale: 0.7,
        duration: 0.3,
        ease: "power2.out",
        onComplete: () => {
          this.$store.commit("modals/attendeeModal", false);
          this.block = false;
        },
      });
    },
  },
  computed: {
    ...mapState("attendees", ["edit"]),
    items() {
      let lang = [
        { display: this.$t("languages.en"), value: "en" },
        { display: this.$t("languages.de"), value: "de" },
        { display: this.$t("languages.fr"), value: "fr" },
        { display: this.$t("languages.sk"), value: "sk" },
        { display: this.$t("languages.pl"), value: "pl" },
        { display: this.$t("languages.el"), value: "el" },
        { display: this.$t("languages.es"), value: "es" },
        { display: this.$t("languages.nl"), value: "nl" },
        { display: this.$t("languages.fr"), value: "fr" },
        { display: this.$t("languages.hi"), value: "hi" },
        { display: this.$t("languages.zh-cn"), value: "zh-cn" },
        { display: this.$t("languages.zh-tw"), value: "zh-tw" },
        { display: this.$t("languages.pt"), value: "pt" },
      ];
      if (!this.edit) return lang;
      let find = lang.find((el) => el.value == this.edit.lang);
      if (!find) lang[0].selected = true;
      else find.selected = true;
      return lang;
    },
    action() {
      return this.edit
        ? this.$t("modal.addAttendee.edit")
        : this.$t("modal.addAttendee.create");
    },
  },
  mounted() {
    gsap.from(".modal_page", {
      opacity: 0,
      duration: 0.3,
      ease: "power2.out",
    });

    gsap.from(".modal_window", {
      scale: 0.7,
      duration: 0.3,
      ease: "power2.out",
    });

    if (this.edit) {
      this.name = this.edit.name;
      this.email = this.edit.email;
      this.phone = this.edit.phone;
      this.selected = this.edit.color;
      this.timezone = this.edit.timezone;
      this.language = this.edit.language;
    }
  },
};
</script>

<style scoped>
.modal_page {
  position: fixed;
  left: 0;
  top: 0px;
  width: 100vw;
  height: 100vh;
  background: rgba(41, 41, 41, 0.6);
  z-index: 8;
  overflow: overlay;
}

.modal_window {
  width: 450px;
  padding: 30px;
  padding-left: 60px;
  padding-right: 60px;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.35);
  background-color: #243748;
}

.grid {
  display: grid;
  grid-template-columns: 150px auto;
  grid-row-gap: 15px;
}

.color {
  height: 32px;
  width: 32px;
  border-radius: 50%;
  margin: 5px;
  border: 1px solid rgba(0, 0, 0, 0);
}

.color:hover {
  box-shadow: 0 0 12px var(--green);
}

.active {
  box-shadow: 0 0 20px var(--green);
  border: 1px solid rgb(255, 255, 255);
}

@media only screen and (max-width: 576px) {
  .modal_window {
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
  }
}
</style>