<template>
  <FocusLoop>
    <div class="modal_page center">
      <form
        @submit.prevent="post"
        class="modal_window d-flex d-col space-between"
      >
        <div class="d-flex space-between align-center">
          <div v-if="!edit.attendees" class="font-18">
            {{ $t("modal.update.title") }}
          </div>
          <div v-if="edit.attendees" class="font-18">
            {{ $t("modal.update.group") }}
          </div>
          <s-icon @click.native="closeModal" color="grey">close</s-icon>
        </div>

        <div v-if="!edit.attendees" class="pt-30 opacity-60 font-14">
          {{ $t("modal.update.confirm") }}
        </div>
        <div v-if="edit.attendees" class="pt-30 opacity-60 font-14">
          {{ $t("modal.update.group_confirm") }}
        </div>
        <div class="d-flex justify-end mt-40">
          <s-btn
            @click.native="closeModal"
            type="button"
            height="36"
            class="mr-10"
          >
            {{ $t("modal.cancel") }}
          </s-btn>
          <s-btn type="submit" height="36" class="green">
            {{ $t("modal.save") }}</s-btn
          >
        </div>
      </form>
    </div>
  </FocusLoop>
</template>

<script>
import { gsap } from "gsap";
import { mapState } from "vuex";
export default {
  methods: {
    async post() {
      if (!this.edit.attendees) {
        await this.$store.dispatch("attendees/updateAttendees");
        await this.$store.dispatch("attendees/getAttendeesInformation");
      } else {
        await this.$store.dispatch("attendees/updateGroups");
        await this.$store.dispatch("attendees/getGroups");
      }
      this.closeModal();
    },
    closeModal() {
      this.$store.dispatch("attendees/getAttendeesInformation");
      gsap.to(".modal_page", {
        opacity: 0,
        duration: 0.3,
        ease: "power2.out",
      });
      gsap.to(".modal_window", {
        scale: 0.7,
        duration: 0.3,
        ease: "power2.out",
        onComplete: () =>
          this.$store.commit("modals/updateAttendeeConfirmation", false),
      });
    },
  },
  computed: {
    ...mapState("attendees", ["edit"]),
  },
  mounted() {
    gsap.from(".modal_page", {
      opacity: 0,
      duration: 0.3,
      ease: "power2.out",
    });

    gsap.from(".modal_window", {
      scale: 0.7,
      duration: 0.3,
      ease: "power2.out",
    });
  },
};
</script>

<style scoped>
.modal_page {
  position: fixed;
  left: 0;
  top: 0px;
  width: 100vw;
  height: 100vh;
  background: rgba(41, 41, 41, 0.6);
  z-index: 8;
}

.modal_window {
  width: 450px;
  padding: 30px;
  padding-left: 60px;
  padding-right: 60px;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.35);
  background-color: #243748;
}

@media only screen and (max-width: 576px) {
  .modal_window {
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
  }
}
</style>