<template>
  <FocusLoop>
    <div class="modal_page center" ref="modal_page">
      <form ref="modal_window" class="modal_window d-flex">
        <div v-if="page < 4" class="left">
          <div v-if="!isMobile" class="toolbar d-flex space-between">
            <div>{{ $t("billing.title") }}</div>
            <s-icon @click.native="closeModal" color="white">close</s-icon>
          </div>
          <div class="body h100 align-center d-flex d-col">
            <div class="weight-300 font-28 green--text my-30">
              {{ $t("billing.your_plan") }}
            </div>
            <div class="weight-300 my-10">
              {{ $t("billing.monthly_payments") }}
            </div>
            <div class="weight-300 font-40 my-10">{{ monthlyPayment }}</div>
            <div class="weight-300 my-10">
              {{ $t("billing.including") }} {{ rooms }}
              {{ $t("billing.rooms") }}
            </div>
            <div class="weight-300 font-14 my-10">
              {{ $t("billing.autopay") }} {{ nextBilling }}
            </div>
            <div
              class="weight-300 font-14 my-10"
              v-if="isFree && !expiresInThePast"
            >
              {{ $t("billing.trial_ends") }} {{ expires }}
            </div>
            <div
              class="weight-300 font-14 my-10"
              v-if="isFree && expiresInThePast"
            >
              {{ $t("billing.trial_ended") }} {{ expires }}
            </div>
            <s-icon
              @click.native="showInfo = !showInfo"
              :class="{ none: isMobile }"
              class="pointer"
              color="grey"
              height="30"
              >alert-circle-outline</s-icon
            >
            <div
              v-if="showInfo"
              class="infoWapper pa-10 mt-5"
              :class="{ none: isMobile }"
            >
              <div class="weight-300 font-14 mt-10">
                {{ $t("billing.confirmation_message") }}
              </div>
            </div>
          </div>
          <div class="infoWapper pa-10" :class="{ none: !isMobile }">
            <s-icon color="grey" height="30">alert-circle-outline</s-icon>
            <div class="weight-300 font-14 mt-10">
              {{ $t("billing.confirmation_message") }}
            </div>
          </div>
        </div>

        <div v-if="page < 4" class="right black--text">
          <div v-if="isMobile" class="toolbar d-flex space-between">
            <div>{{ $t("billing.pro_plan_rooms") }}</div>
            <s-icon @click.native="closeModal">close</s-icon>
          </div>

          <div v-if="page == 1" class="h100 d-flex d-col">
            <div class="grow">
              <div class="formWrapper mt-30 pa-10 mb-10">
                <div class="d-flex mb-20">
                  <div class="grow">{{ $t("billing.current_rooms") }}</div>
                  <div class="font-18 weight-600">
                    {{ entity_rooms }}
                    <span class="weight-400 capital">{{
                      $t("billing.rooms")
                    }}</span>
                  </div>
                </div>
                <div class="d-flex">
                  <div class="grow align-center d-flex">
                    {{ $t("billing.change_room_sub") }}
                  </div>
                  <div class="d-flex align-center">
                    <s-icon height="30" @click.native="decrement" color="black"
                      >chevron-left-circle-outline</s-icon
                    >
                    <div class="px-10 weight-600 font-18">{{ rooms }}</div>
                    <s-icon height="30" @click.native="increment" color="black"
                      >chevron-right-circle-outline</s-icon
                    >
                  </div>
                </div>
              </div>
              <div class="d-flex my-30 mx-10">
                <div class="grow">{{ $t("billing.next_billing") }}</div>
                <div>{{ nextBilling }}</div>
              </div>
              <div class="d-flex my-30 mx-10">
                <div class="grow">{{ $t("billing.you_will_be_charged") }}</div>
                <div v-if="newRooms > 0 || !isPro">{{ remainingCharge }}</div>
                <div v-else>0</div>
              </div>
              <div class="d-flex d-col w100 align-start pt-10 badges">
                <div class="d-flex center w100">
                  <s-icon class="mr-10" color="green"
                    >shield-lock-outline</s-icon
                  >
                  <span class="font-14 mud--text"
                    >{{ $t("billing.guaranteed") }}
                    <span class="weight-600">{{
                      $t("billing.safe_secure")
                    }}</span>
                    {{ $t("billing.checkout") }}</span
                  >
                  <img
                    class="badge ml-auto"
                    src="/img/static/stripe.svg"
                    alt=""
                  />
                </div>
                <hr class="w100 mt-10 opacity-40 ma-0 relative" />
                <div class="d-flex mt-10">
                  <img class="badge" src="/img/static/master-card.svg" alt="" />
                  <img class="badge" src="/img/static/visa-card.svg" alt="" />
                </div>
              </div>
            </div>
            <div class="actionButtons">
              <hr class="mt-10" />
              <div class="pa-10">
                <s-btn
                  type="button"
                  :disabled="disableIfSame"
                  @click.native="next"
                  class="green"
                  height="40"
                  block
                  >{{ $t("billing.next") }}</s-btn
                >
                <s-btn
                  type="button"
                  outlined
                  @click.native="closeModal"
                  class="mt-10"
                  height="40"
                  block
                  >{{ $t("billing.close") }}</s-btn
                >
              </div>
            </div>
          </div>

          <div v-if="page == 2" class="page2 d-flex d-col h100">
            <div class="grid mt-20">
              <label for=""> {{ $t("billing.pay_card") }}</label>
              <input
                type="text"
                disabled
                class="StripeElement"
                :value="'Email: ' + email"
              />

              <div class="d-flex mt-20 space-between">
                <div>{{ $t("billing.charge_amount") }}</div>
                <div class="weight-600 font-18">{{ remainingCharge }}</div>
              </div>
              <hr class="mx-0 opacity-40" />

              <label class="mt-20" for=""> {{ $t("billing.name") }}</label>
              <input
                :placeholder="$t('billing.enter_name_on_card')"
                type="text"
                v-model="name"
                autofocus
                class="StripeElement"
              />
              <div class="d-flex grid-billing">
                <div>{{ $t("billing.pay_with_card") }}</div>
              </div>

              <div id="card-element"></div>
            </div>
            <div class="d-flex d-col justify-end align-center grow pb-10">
              <hr class="w100 mb-20 mx-10" />
              <div class="buttonWrapper center" @click="invalidNotification()">
                <s-btn
                  type="submit"
                  :disabled="disabledUpgrade"
                  height="40"
                  width="300"
                  class="green"
                  @click.native="upgrade"
                  >{{ upgradeText }}
                </s-btn>
              </div>
            </div>
          </div>

          <div v-if="page == 3" class="w100 h100 center d-col">
            <s-icon width="120" color="green" class="pl-20"
              >check-circle-outline</s-icon
            >
            <div class="mt-50 font-20 weight-300">
              {{ $t("billing.thankyou") }}
            </div>
          </div>
        </div>
        <div
          v-if="page == 4"
          class="w100 h100 center d-col justify-end mx-auto mt-60"
        >
          <div class="main_delete center">
            <s-icon width="120" color="white" class="pl-20"
              >information-outline</s-icon
            >
            <div class="mb-30 mt-50 mx-60 weight-300 text-center">
              {{ $t("billing.delete") }} {{ Math.abs(newRooms) }}
              {{ $t("billing.room") }}{{ newRoomsS }}
              {{ $t("billing.revised") }} {{ rooms }} {{ $t("billing.room")
              }}{{ roomsS }}.
            </div>
            <div class="mb-30 mb-50 mx-60 weight-300 text-center">
              {{ $t("billing.billing_date") }}
              {{ currentPayment }} {{ $t("billing.to") }}
              {{ downgradeNewPayment }} {{ $t("billing.per_month") }}
            </div>
            <s-btn
              @click.native="downgraderoomsfunc"
              type="button"
              width="300"
              height="30"
              class="green mx-60 mt-20 mb-10"
              >{{ $t("billing.complete") }}</s-btn
            >
          </div>
        </div>
      </form>
    </div>
  </FocusLoop>
</template>
<script>
import { loadStripe } from "@stripe/stripe-js";
import { mapState } from "vuex";
import { gsap } from "gsap";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
dayjs.extend(relativeTime);
export default {
  data() {
    return {
      message: "",
      name: "",
      page: 1,
      rooms: 1,
      complete: false,
      showInfo: false,
      stipe: null,
      card: null,
      code: "",
      processingPayment: false,
      priceFromAPI: "",
      isMobile: true,
    };
  },
  methods: {
    increment() {
      this.rooms = Number(this.rooms) + 1;
    },
    decrement() {
      if (this.rooms <= 1) return;
      this.rooms = Number(this.rooms) - 1;
    },
    async post() {
      let response = await this.$store.dispatch("billing/getKey");
      let key = response.pubKey;
      this.stripe = await loadStripe(key);
      const elements = this.stripe.elements();
      let style = {
        base: {
          backgroundColor: "#ffffff",
          fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
          fontSmoothing: "antialiased",
          fontSize: "16px",
          "::placeholder": {
            color: "#aab7c4",
          },
        },
        invalid: {
          color: "#fa755a",
          iconColor: "#fa755a",
        },
      };

      this.card = elements.create("card", { style: style });
      this.card.mount("#card-element");

      this.card.on("change", (error) => {
        if (error.error)
          this.$store.commit("notifications/push", {
            error: error.error.message,
          });
        this.complete = error.complete;
      });
    },
    async downgraderoomsfunc() {
      let res = await this.$store.dispatch("billing/downgrade", this.newRooms);
      this.$store.commit("notifications/raw", res);
      this.$store.dispatch("user/getUserInformation");
      return this.closeModal();
    },
    async upgrade() {
      if (this.processingPayment) return;
      this.processingPayment = true;
      let payment = await this.stripe.confirmCardPayment(this.code, {
        payment_method: {
          card: this.card,
          billing_details: {
            name: this.name,
          },
        },
      });
      if (payment.error) {
        this.processingPayment = false;
        return this.$store.commit("notifications/push", {
          error: payment.error.message,
        });
      }

      if (payment.paymentIntent.status !== "succeeded")
        return this.$store.commit("notifications/push", {
          error: this.$t("billing.unknown") + payment.paymentIntent.id,
        });

      if (this.isFree) {
        let ob = { rooms: this.rooms, paymentIntent: payment.paymentIntent };
        let res = await this.$store.dispatch("billing/postFreeToPro", ob);
        this.page = 3;
        this.processingPayment = false;
      }

      if (!this.isFree) {
        let res = await this.$store.dispatch(
          "billing/finishUpgrade",
          payment.paymentIntent
        );
        this.page = 3;
        this.processingPayment = false;
      }

      this.$store.dispatch("user/getUserInformation");
    },
    invalidNotification() {
      if (this.processingPayment) return;
      if (!this.disabledUpgrade) return;
      return this.$store.commit("notifications/push", {
        error: this.$t("billing.incomplete_payment_information"),
      });
    },
    closeModal() {
      gsap.to(this.$refs.modal_window, {
        scale: 0.7,
        duration: 0.3,
        ease: "power2.out",
        onComplete: async () => {
          this.$store.commit("modals/billingModal", false);
          if (this.isFree && !this.isTrial) {
            await this.$store.dispatch("user/logout");
            this.$router.push("/login");
          }
        },
      });

      gsap.to(this.$refs.modal_page, {
        opacity: 0,
        duration: 0.3,
        ease: "power2.out",
      });
    },
    async next() {
      if (this.disableIfSame) return;
      if (this.newRooms < 0) return (this.page = 4);
      if (this.isFree) {
        let a = await this.$store.dispatch("billing/paymentIntent", this.rooms);
        if (a.status === "ok") {
          this.code = a.code;
          this.page = 2;
          this.post();
        }
        if (a.status === "error") {
          return this.$store.commit("notifications/push", {
            error: a.error,
          });
        }
      }

      if (!this.isFree) {
        let b = await this.$store.dispatch(
          "billing/addMoreRoomsPro",
          this.newRooms
        );
        this.priceFromAPI = b.price;
        let a = await this.$store.dispatch("billing/getUpgradeSecret");
        if (a.status === "ok") {
          this.code = a.code;
          this.page = 2;
          this.post();
        }
        if (a.status === "error") {
          return this.$store.commit("notifications/push", {
            error: a.error,
          });
        }
      }
    },
  },
  watch: {
    name(val) {
      if (this.name === "" && this.disableUpgradeButton)
        return (this.message = this.$t("billing.enter_name"));
      if (this.name.length > 0) this.message = "";
      this.disableUpgradeButton = !!(
        this.disableUpgradeButton && this.name.length > 0
      );
    },
  },
  computed: {
    ...mapState("user", [
      "email",
      "firstName",
      "lastName",
      "entity_rooms",
      "entity_currency",
      "productPrice",
      "entity_account_type",
      "entity_stripe_next_billing",
      "registrationDate",
      "isTrial",
      "lang",
    ]),
    monthlyPayment() {
      let paymentCalculation = (this.rooms * this.productPrice).toFixed(2);
      return paymentCalculation + " " + this.entity_currency;
    },
    remainingCharge() {
      let nextBillingDate = dayjs.unix(this.entity_stripe_next_billing);
      let diff = nextBillingDate.diff(dayjs(), "day");
      let month = dayjs().daysInMonth() - 1;
      if (nextBillingDate.isSame(dayjs(), "day")) diff = month;
      let price = ((this.productPrice / month) * diff).toFixed(2);
      let rooms = (this.rooms - this.entity_rooms).toFixed(0);
      let proPlan = (price * rooms).toFixed(2) + " " + this.entity_currency;
      return this.isPro ? proPlan : this.monthlyPayment;
    },
    newPayment() {
      let val = (this.rooms - this.entity_rooms) * this.productPrice;
      return val.toFixed(2) + " " + this.entity_currency;
    },
    newRoomsS() {
      return Math.abs(this.newRooms) === 1 ? "" : "s";
    },
    roomsS() {
      return this.rooms === 1 ? "" : "s";
    },
    downgradeNewPayment() {
      return (
        (this.rooms * this.productPrice).toFixed(2) + " " + this.entity_currency
      );
    },
    formatBillingDate() {
      return dayjs.unix(this.entity_stripe_next_billing).format("DD/MMM/YY");
    },
    isPro() {
      return this.entity_account_type !== "free";
    },
    isFree() {
      return this.entity_account_type == "free";
    },
    expires() {
      let day = dayjs(this.registrationDate).locale(this.lang);
      return day.add(2, "weeks").from(dayjs());
    },
    expiresInThePast() {
      let day = dayjs(this.registrationDate);
      return day.add(2, "weeks").isBefore(dayjs());
    },
    currentPayment() {
      let val = (this.entity_rooms * this.productPrice).toFixed(2);
      return val + " " + this.entity_currency;
    },
    disableButton() {
      if (this.entity_account_type === "free") return false;
      return this.rooms > this.entity_rooms;
    },
    nextBilling() {
      if (this.entity_account_type === "free")
        return dayjs().add(1, "month").format("MMM DD YYYY");
      return dayjs.unix(this.entity_stripe_next_billing).format("MMM DD YYYY");
    },
    disabledUpgrade() {
      if (this.processingPayment) return true;
      return !(this.complete && this.name.length > 1);
    },
    upgradeText() {
      return this.processingPayment
        ? this.$t("billing.processing_payments")
        : this.$t("billing.upgrade");
    },
    redActionButton() {
      if (this.page1 === 3) return this.$t("billing.close");
      return !this.isTrial && this.isFree
        ? this.$t("billing.logout")
        : this.$t("billing.cancel");
    },
    disableIfSame() {
      if (this.entity_account_type === "free") return false;
      return this.rooms === this.entity_rooms;
    },
    greenActionButton() {
      return this.isFree
        ? this.$t("billing.next")
        : this.$t("billing.subscribe");
    },
    newRooms() {
      return this.rooms - this.entity_rooms;
    },
  },
  mounted() {
    this.rooms = this.entity_rooms;
    this.$store.dispatch("user/getUserInformation");
    if (this.isFree) this.$store.dispatch("billing/getBilling");
    if (this.isPro) this.$store.dispatch("billing/getBillingUpgrade");
    gsap.from(this.$refs.modal_window, {
      scale: 0.7,
      duration: 0.3,
      ease: "power2.out",
    });

    const mq = window.matchMedia("(max-width: 768px)");
    this.isMobile = !mq.matches;
    mq.addListener((mq) => {
      this.isMobile = !mq.matches;
    });
  },
};
</script>

<style scoped>
.modal_page {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(41, 41, 41, 0.6);
  z-index: 8;
  overflow: auto;
}

.badges {
  background-color: #bfd1e3;
  border-radius: 5px;
  padding: 10px;
  box-sizing: border-box;
}

.hr {
  border: none;
  border-bottom: 1px solid white;
  margin: 0;
}

.main_delete {
  max-width: 500px;
}

.note {
  grid-column: 1 / span 2;
  font-size: 12px;
}

ul {
  padding-inline-start: 20px;
  margin: 0;
}

.buttonWrapper {
  width: 300px;
}

.account_modal {
  height: 530px;
  width: 100%;
  max-width: 550px;
  padding-top: 30px;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.35);
  background-color: #243748;
}

.input_text {
  border-radius: 5px !important;
  border: solid 1px #6388aa !important;
  background-color: #ffffff;
  opacity: 1;
  color: #395c7d;
  float: left;
}

.StripeElement {
  box-sizing: border-box;
  height: 40px;
  padding: 10px 12px;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
  width: 100%;
  border-radius: 5px !important;
  border: solid 1px #6388aa !important;
  background-color: white;
  opacity: 1;
  color: #395c7d;
  font-size: 16px;
  font-family: roboto;
}

.grid {
  display: grid;
  background-color: #bfd1e3;
  grid-row-gap: 10px;
  padding: 10px;
  border-radius: 5px;
}

.page1-grid {
  display: grid;
  padding-bottom: 0px;
  grid-template-columns: 300px auto;
}

.grid-billing {
  display: grid;
  grid-template-columns: 300px auto;
}

.strength_container {
  grid-column-gap: 4px;
  min-height: 2px;
  margin-top: 5px;
}

.strength {
  width: 72px;
  height: 2px;
  grid-column-gap: 5px;
}

.right {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  width: 100%;
  background-color: #d0e3f4;
  padding: 10px;
  box-sizing: border-box;
}

.left {
  width: 400px;
  box-sizing: border-box;
  padding: 10px;
  min-height: 100%;
  background-color: #243748;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.badge {
  max-width: 180px;
  max-height: 25px;
  margin-right: 10px;
}

.infoWapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #3b4d5b;
  border-radius: 5px;
}

.formWrapper {
  background-color: rgb(191, 208, 226);
  border-radius: 5px;
}

.modal_window {
  max-width: 768px;
  width: 100%;
  min-height: 500px;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.35);
  background-color: #243748;
}

@media only screen and (max-width: 768px) {
  .modal_window {
    flex-direction: column;
  }

  .left {
    min-height: 200px;
    width: 100%;
    display: block;
  }

  .right {
    min-height: 100%;
    flex-grow: 1;
  }
}

@media only screen and (max-width: 768px) {
  .grid {
    display: flex;
    flex-direction: column;
    padding-left: 10px;
    padding-right: 10px;
  }
}
</style>
