import router from "../router/index";
import store from "../store/index";

export const auth = function (response) {
  if (!response) return true;
  if (router.currentRoute.name === "Login") return true;
  if (response.errorCode !== "authentication_error") return true;
  if (!store.state.user.email) return true;
  store.commit("user/clear");
  router.push("/login");
  return false;
};

const header = { "Content-type": "application/json; charset=UTF-8" };

export const get = async function (url) {
  url = process.env.VUE_APP_URL + url;
  let response = await fetch(url, {
    method: "GET",
    credentials: "include",
    headers: header,
  });
  response = await response.json();
  auth(response);
  return response;
};

export const post = async function (url, data) {
  url = process.env.VUE_APP_URL + url;
  let response = await fetch(url, {
    method: "POST",
    body: JSON.stringify(data),
    credentials: "include",
    headers: header,
  });
  response = await response.json();
  auth(response);
  return response;
};

export const del = async function (url) {
  url = process.env.VUE_APP_URL + url;
  let response = await fetch(url, {
    method: "DELETE",
    credentials: "include",
    headers: header,
  });
  response = await response.json();
  auth(response);
  return response;
};
export const validateEmail = function (email) {
  return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)
}
