import { render, staticRenderFns } from "./AddAttendee.vue?vue&type=template&id=56eb1f60&scoped=true&"
import script from "./AddAttendee.vue?vue&type=script&lang=js&"
export * from "./AddAttendee.vue?vue&type=script&lang=js&"
import style0 from "./AddAttendee.vue?vue&type=style&index=0&id=56eb1f60&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56eb1f60",
  null
  
)

export default component.exports