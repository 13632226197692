<template>
  <div v-show="show" ref="modal_page" class="drawer_page show_on_mobile">
    <div ref="modal" class="drawer_modal"  v-click-outside:mobile_chevron="close">
      <div v-if="email">
        <div
          v-for="item in items"
          :key="item.idx"
          @click="open(item)"
          class="d-flex align-center item"
        >
          <s-icon color="white">{{ item.icon }}</s-icon>
          <div class="ml-10 uppercase">{{ item.title }}</div>
        </div>
      </div>
      <div v-if="!email">
        <div
          v-for="item in itemsGuest"
          :key="item.idx"
          @click="open(item)"
          class="d-flex align-center item"
        >
          <s-icon color="white">{{ item.icon }}</s-icon>
          <div class="ml-10 uppercase">{{ item.title }}</div>
        </div>
      </div>
      <div class="d-flex justify-end">
        <button @click="openAppInfo" :title="$t('toolbars.drawer.info')">
          <s-icon color="grey">information-outline</s-icon>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { gsap } from "gsap";
export default {
  data() {
    return {
      show: false,
    };
  },
  methods: {
    open(val) {
      if (val.action == "route") {
        if (this.$route.path != val.to) this.$router.push(val.to);
      }
      if (val.action == "modal") {
        this.$store.commit(val.to, true);
      }
      if (val.action == "function") {
        if (val.icon == "logout") this.logout();
      }

      this.$store.commit("toolbars/drawer", false);
    },
    logout() {
      this.$store.dispatch("user/logout");
      this.$store.dispatch("calendar/clearUpcomingEvents");
      this.$router.push("/login");
    },
    openAppInfo() {
      this.$store.commit("toolbars/toggleDrawer");
      this.$store.commit("modals/appInfo", true);
    },
    close(){
       this.$store.commit("toolbars/drawer", false);
    }
  },
  computed: {
    items() {
      return [
        {
          icon: "video-outline",
          title: this.$t("toolbars.drawer.new_meeting"),
          to: "modals/meetingModal",
          action: "modal",
        },
        {
          icon: "home",
          title: this.$t("toolbars.drawer.dashboard"),
          to: "/",
          action: "route",
        },
        {
          icon: "calendar-plus",
          title: this.$t("toolbars.drawer.schedule"),
          to: "/schedule",
          action: "route",
        },
        {
          icon: "calendar-week",
          title: this.$t("toolbars.drawer.calendar"),
          to: "/calendar",
          action: "route",
        },

        {
          icon: "door-open",
          to: "/rooms",
          action: "route",
          title: this.$t("toolbars.drawer.rooms"),
        },
        {
          icon: "account-supervisor",
          to: "/attendees",
          action: "route",
          title: this.$t("toolbars.drawer.attendees"),
        },
        {
          icon: "cog",
          title: this.$t("toolbars.drawer.settings"),
          to: "modals/accountModal",
          action: "modal",
        },
        {
          icon: "frequently-asked-questions",
          to: "/faq",
          action: "route",
          title: this.$t("toolbars.drawer.faq"),
        },

        {
          icon: "logout",
          title: this.$t("toolbars.drawer.logout"),
          to: "/login",
          action: "function",
        },
      ];
    },
    itemsGuest() {
      return [
        {
          icon: "account-plus",
          title: this.$t("registration.register"),
          to: "/register",
          action: "route",
        },
        {
          icon: "login",
          title: this.$t("registration.login"),
          to: "/login",
          action: "route",
        },
      ];
    },
    ...mapState("toolbars", ["drawer"]),
    ...mapState("user", ["email"]),
  },
  watch: {
    drawer(val) {
      this.show = true;
      if (val) {
        gsap.to(this.$refs.modal, {
          duration: 0.5,
          x: "0%",
          onUpdate: () => (this.show = true),
        });
        gsap.to(this.$refs.modal_page, {
          duration: 0,
          backgroundColor: "#29292999",
        });
      } else {
        gsap.to(this.$refs.modal, {
          duration: 0.5,
          x: "100%",
          onComplete: () => (this.show = false),
        });
        gsap.to(this.$refs.modal_page, {
          duration: 0,
          backgroundColor: "#29292900",
        });
      }
    },
  },
  mounted() {
    this.$store.commit("toolbars/drawer", false);
  },
};
</script>

<style scoped lang='less'>
.drawer_page {
  display: flex;
  justify-content: flex-end;
  position: fixed;
  top: 60px;
  width: 100vw;
  height: 101vh;
  background: #29292900;
  z-index: 2;
}

.drawer_modal {
  width: 80%;
  transform: translateX(100%);
  min-height: 475px;
  max-width: 600px;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.35);
  background-color: #243748;
  padding: 20px;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 60px);
  box-sizing: border-box;
  justify-content: space-between;
}

.item {
  color: white;
  height: 50px;
  cursor: pointer;
}

.show_on_mobile {
  display: none;
}

@media only screen and (max-width: 576px) {
  .show_on_mobile {
    display: flex;
  }
}
</style>